<template>
  <Basic-Container>
    <avue-crud
      class="avueCrud"
      ref="crud"
      v-model="crudModel"
      :page.sync="page"
      :data="tableData"
      @row-save="rowSave"
      @row-del="rowDel"
      @row-update="rowUpdate"
      @search-change="searchChange"
      @search-reset="searchReset"
      @size-change="sizeChange"
      @current-change="currentChange"
      @refresh-change="refreshChange"
      :before-open="beforeOpen"
      :table-loading="tableLoading"
      :option="tableOption"
    >
      <!-- 排序 -->
      <template v-slot:sequence="{ row }">
        <div class="sort-css">
          <div>{{ row.sequence }}</div>
          <div
            class="sort-icon"
            v-if="row.sequence !== 1"
            @click="sortClick('top', row)"
          >
            <el-button
              class="icon-btn"
              type="primary"
              icon="el-icon-top"
            ></el-button>
          </div>
          <div class="sort-icon" @click="sortClick('bottom', row)">
            <el-button
              class="icon-btn"
              type="warning"
              icon="el-icon-bottom"
            ></el-button>
          </div>
        </div>
      </template>
      <template v-slot:cemeteryIds="{ row }">
        <div v-for="item of row.recommendCemetery" :key="item.id">
          {{ item.cemeteryName }}
        </div>
      </template>
      <template v-slot:cityCode="{ row }">
        {{ row.cityName }}
      </template>
      <template slot-scope="{ row }" slot="recommendImage">
        <div v-if="row.recommendedTombs && row.recommendedTombs != ''">
          <template v-for="rec in row.recommendedTombs">
            <div :key="rec.id">
              {{ rec.tombName }}
            </div>
          </template>
        </div>
        <div v-else>
          <span>暂无推荐墓型</span>
        </div>
      </template>
      <template slot-scope="{ row }" slot="cityCodeSearch">
        <el-select
          filterable
          clearable
          v-model="row.cityCode"
          placeholder="请选择"
        >
          <el-option
            v-for="item in citys"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="locationForm">
        <el-cascader
          filterable
          v-model="crudModel.location"
          :show-all-levels="false"
          :props="props"
          :options="pc"
          @change="changeCityCode"
        ></el-cascader>
      </template>
      <template slot="recommendImageForm">
        <UploadBtn
          :limit="1"
          tips="上传数量需为双数, 最多100张"
          v-model="crudModel.recommendImage"
        />
      </template>
      <template slot="recommendedTombsForm">
        <div class="addBtns">
          <el-button type="primary" @click="addClick">新增推荐墓型</el-button>
        </div>
        <div
          v-if="
            crudModel.recommendedTombs && crudModel.recommendedTombs.length > 0
          "
        >
          <div
            class="cemeteryForm"
            v-for="(item, index) of crudModel.recommendedTombs"
            :key="index"
          >
            <div class="title-wrap">
              <div class="title">第{{ index + 1 }}项墓型</div>
              <el-button type="danger" @click="delItem(index)">删除</el-button>
            </div>

            <el-form-item
              label="墓型图片"
              :prop="'recommendedTombs.' + index + '.recommendImage'"
              :rules="[
                {
                  required: true,
                  message: '请上传墓型图片',
                  trigger: 'change',
                },
              ]"
            >
              <UploadBtn :limit="1" tips="" v-model="item.recommendImage"
            /></el-form-item>
            <el-form-item
              label="墓型名称"
              :prop="'recommendedTombs.' + index + '.tombName'"
              :rules="[
                {
                  required: true,
                  message: '请输入墓型名称',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="item.tombName"
                placeholder="请输入墓型名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="参考价"
              :prop="'recommendedTombs.' + index + '.referencePrice'"
              :rules="[
                { required: true, message: '请输入参考价', trigger: 'blur' },
              ]"
            >
              <el-input-number
                style="width: 100%"
                v-model="item.referencePrice"
                :min="1"
                :controls="false"
                placeholder="请输入参考价"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="所属陵园"
              :prop="'recommendedTombs.' + index + '.cemeteryId'"
              :rules="[
                {
                  required: true,
                  message: '请选择所属陵园',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="item.cemeteryId" placeholder="请选择所属陵园">
                <el-option
                  v-for="ly in cemeteryList"
                  :key="ly.id"
                  :label="ly.cemeteryName"
                  :value="ly.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div v-else>
          <el-empty description="请先上传推荐墓型图片"></el-empty>
        </div>
      </template>
    </avue-crud>
  </Basic-Container>
</template>
<script>
import { tableOption } from "./tableOption.js";
import { citys, getName, pc } from "@/util/location/index.js";
import {
  getRecommendApi,
  postEditApi,
  deleteApi,
  getCemeteryListdApi,
  postUpdateRankApi
} from "@/api/xmwmanage/recommended.js";
export default {
  name: "recommended",
  filters: {},
  components: {
    UploadBtn: () => import("@/components/uploadList/index.vue"),
  },
  data() {
    return {
      searchForm: {},
      crudModel: {
        recommendedTombs: [],
      },
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 10, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      props: {
        value: "code",
        label: "name",
      },
      pc: pc,
      citys: citys,
      cemeteryList: [],
    };
  },
  created() {
    this.getList(this.page);
    this.getCemeteryListFun();
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getRecommendApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchReset(from) {
      if (from.cityCode) {
        from.cityCode = "";
      }
      this.searchForm.cityCode && (this.searchForm.cityCode = "");
    },
    async rowSave(form, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      let res = await this.checkForm(params);
      if (!res.flag) {
        this.$message.error(res.msg);
        return loading();
      }
      let newParams = await this.setParams(params, "add");
      postEditApi(newParams)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
          return loading();
        });
    },
    async rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      let newParams = await this.setParams(params, "edit");
      postEditApi(newParams)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    beforeOpen(done, type) {
      if (type === "add") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.crudModel.recommendImage = [];
            this.crudModel.recommendedTombs = [];
          }, 0);
        });
      } else {
        let row = JSON.parse(JSON.stringify(this.crudModel));
        this.crudModel.recommendedTombs = [];
        this.$nextTick(() => {
          setTimeout(() => {
            for (let i = 0; i < row.recommendedTombs.length; i++) {
              const item = row.recommendedTombs[i];
              item.recommendImage = [item.recommendImage];
              item.referencePrice = item.referencePrice / 100;
              if (
                (item.referencePrice + "").split(".")[1] &&
                (item.referencePrice + "").split(".")[1].length > 2
              ) {
                item.referencePrice = item.referencePrice.toFixed(2);
              }
            }
            this.$set(this.crudModel, "recommendedTombs", row.recommendedTombs);
          }, 0);
        });

        console.log(row.recommendedTombs, "row");
        this.crudModel.recommendImage = [];
        for (let i = 0; i < row.recommendedTombs.length; i++) {
          const item = row.recommendedTombs[i];
          this.crudModel.recommendImage.push(item.recommendImage);
        }
        this.crudModel.cemeteryIds = row.recommendCemetery.map((item) => {
          return item.id;
        });
        this.$set(this.crudModel, "location", [row.provinceCode, row.cityCode]);
        this.$forceUpdate();
      }
      done();
    },
    refreshChange() {
      this.getList(this.page);
    },
    setParams(params, type = "edit") {
      return new Promise((resolve) => {
        for (let i = 0; i < params.recommendedTombs.length; i++) {
          const item = params.recommendedTombs[i];
          item.recommendImage = item.recommendImage[0];
          item.referencePrice = (item.referencePrice * 100).toFixed(0);
        }
        let newParams = {
          cemeteryIds: params.cemeteryIds,
          cityCode: params.location[1],
          cityName: getName(params.location[1], 1),
          provinceCode: params.location[0],
          provinceName: getName(params.location[0], 0),
          recommendImage: params.recommendImage[0],
          recommendedTombs: params.recommendedTombs,
          abbreviation: params.abbreviation,
          seoTitle: params.seoTitle,
          seoDesc: params.seoDesc,
          seoKeywords: params.seoKeywords,
        };
        if (type === "edit") {
          newParams.id = params.id;
        }
        resolve(newParams);
      });
    },
    // 删除
    rowDel(form, index) {
      console.log(form, "form");
      this.$confirm(`确定要删除${form.cityName}的首页推荐吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteApi(form.id)
            .then((res) => {
              this.$message.success("删除成功");
              this.getList(this.page);
            })
            .catch((err) => {
              console.log(err, "错误");
              this.$message.warning("网络错误,请稍候重试");
            });
        })
        .catch(() => {});
    },
    changeCityCode(value) {
      console.log(value, "发生变化了啊");
    },
    // 获取园区列表
    getCemeteryListFun() {
      getCemeteryListdApi().then((res) => {
        this.cemeteryList = res.data?.data || [];
      });
    },
    checkForm(data) {
      return new Promise((resolve) => {
        let flag = true;
        let msg = "";
        let imgs = data.recommendImage;
        if (imgs.length % 2 > 0) {
          flag = false;
          msg = "推荐墓型需为双数";
        } else {
          for (let i = 0; i < data.recommendedTombs.length; i++) {
            const item = data.recommendedTombs[i];
            for (let k = 0; k < data.recommendedTombs.length; k++) {
              const kitem = data.recommendedTombs[k];
              if (kitem.cemeteryId === item.cemeteryId && i !== k) {
                flag = false;
                msg = `第 ${k + 1} 项推荐墓型所属陵园和第 ${
                  i + 1
                } 项推荐墓型所属陵园相同, 请重新选择`;
                break;
              }
            }
            if (flag) {
              break;
            }
          }
        }
        resolve({
          flag,
          msg,
        });
      });
    },
    addClick() {
      this.crudModel.recommendedTombs.push({
        cemeteryId: "",
        referencePrice: "",
        tombName: "",
        recommendImage: [],
      });
    },
    delItem(index) {
      this.$confirm(`确定要删除当前项吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.crudModel.recommendedTombs.splice(index, 1);
      });
    },
    sortClick(type, row) {
      let str = type === "top" ? "提升" : "降低";
      let id = row.id;
      this.$confirm(`确定${str}排序吗 ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let operateType = type === "top" ? 1 : 2;
          postUpdateRankApi({
            id,
            operateType,
          }).then((res) => {
            this.$message.success("操作成功!");
            this.getList(this.page);
          });
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.cemeteryForm {
  border: 1px solid rgba(153, 153, 153, 0.2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    text-align: right;
    font-weight: bold;
    color: #0e8cdb;
  }
}
.addBtns {
  margin-bottom: 20px;
}
.sort-css /deep/ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  .sort-icon {
    margin: 0 4px;
  }
  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    padding: 2px 4px;
    font-size: 18px;
  }
}
</style>
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: true,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  labelWidth: 100,
  addBtn: true,
  addBtnText: '新增首页推荐',
  refreshBtn: true,
  editBtnText: '修改',
  column: [
    {
      type: 'input',
      label: '排序',
      prop: 'sequence',
      addDisplay: false,
      editDisplay: false,
      slot: true
    },
    {
      type: 'input',
      label: 'ID',
      prop: 'id',
      addDisplay: false,
      editDisplay: false,
      hide: true
    },
    {
      type: 'select',
      label: '选择城市（省/市）',
      prop: 'location',
      hide: true,
      formslot: true,
      span: 16,
      labelWidth: 160,
      rules: [{
        required: true,
        message: "请选择所在地（只选到市级就行)",
        trigger: "change"
      }]
    },
    {
      type: 'select',
      label: '城市',
      prop: 'cityCode',
      search: true,
      overHidden: true,
      addDisplay: false,
      editDisplay: false
    },
    
    {
      type: 'input',
      label: '城市代码缩写',
      prop: 'abbreviation',
      span: 20,
      rules: [{
        required: true,
        message: "请输入城市缩写",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO标题',
      prop: 'seoTitle',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO标题",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO描述',
      prop: 'seoDesc',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO描述",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO关键词',
      prop: 'seoKeywords',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO关键词",
        trigger: "blur"
      }]
    },
    {
      type: 'select',
      label: '推荐陵园',
      prop: 'cemeteryIds',
      multiple: true,
      filterable: true,
      span: 16,
      slot: true,
      labelWidth: 160,
      dicUrl: "/admin/cemetery/list",
      props: {
        label: "cemeteryName",
        value: "id"
      },
      rules: [{
        required: true,
        message: "请选择推荐陵园",
        trigger: "change"
      }]
    },
    {
      type: 'select',
      label: '推荐墓型',
      prop: 'recommendImage',
      span: 16,
      labelWidth: 160,
      rules: [{
        required: true,
        message: "请上传推荐墓型图片",
        trigger: "change"
      }],
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'select',
      label: '推荐墓型',
      prop: 'recommendedTombs',
      hide: true,
      formslot: true,
      span: 16,
      labelWidth: 160,
      rules: [{
        required: true,
        message: "请设置陵园信息",
        trigger: "change"
      }]
    },
    {
      type: 'input',
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false
    }
  ]
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Basic-Container',[_c('avue-crud',{ref:"crud",staticClass:"avueCrud",attrs:{"page":_vm.page,"data":_vm.tableData,"before-open":_vm.beforeOpen,"table-loading":_vm.tableLoading,"option":_vm.tableOption},on:{"update:page":function($event){_vm.page=$event},"row-save":_vm.rowSave,"row-del":_vm.rowDel,"row-update":_vm.rowUpdate,"search-change":_vm.searchChange,"search-reset":_vm.searchReset,"size-change":_vm.sizeChange,"current-change":_vm.currentChange,"refresh-change":_vm.refreshChange},scopedSlots:_vm._u([{key:"sequence",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"sort-css"},[_c('div',[_vm._v(_vm._s(row.sequence))]),(row.sequence !== 1)?_c('div',{staticClass:"sort-icon",on:{"click":function($event){return _vm.sortClick('top', row)}}},[_c('el-button',{staticClass:"icon-btn",attrs:{"type":"primary","icon":"el-icon-top"}})],1):_vm._e(),_c('div',{staticClass:"sort-icon",on:{"click":function($event){return _vm.sortClick('bottom', row)}}},[_c('el-button',{staticClass:"icon-btn",attrs:{"type":"warning","icon":"el-icon-bottom"}})],1)])]}},{key:"cemeteryIds",fn:function(ref){
var row = ref.row;
return _vm._l((row.recommendCemetery),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.cemeteryName)+" ")])})}},{key:"cityCode",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cityName)+" ")]}},{key:"recommendImage",fn:function(ref){
var row = ref.row;
return [(row.recommendedTombs && row.recommendedTombs != '')?_c('div',[_vm._l((row.recommendedTombs),function(rec){return [_c('div',{key:rec.id},[_vm._v(" "+_vm._s(rec.tombName)+" ")])]})],2):_c('div',[_c('span',[_vm._v("暂无推荐墓型")])])]}},{key:"cityCodeSearch",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"请选择"},model:{value:(row.cityCode),callback:function ($$v) {_vm.$set(row, "cityCode", $$v)},expression:"row.cityCode"}},_vm._l((_vm.citys),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)]}}]),model:{value:(_vm.crudModel),callback:function ($$v) {_vm.crudModel=$$v},expression:"crudModel"}},[_c('template',{slot:"locationForm"},[_c('el-cascader',{attrs:{"filterable":"","show-all-levels":false,"props":_vm.props,"options":_vm.pc},on:{"change":_vm.changeCityCode},model:{value:(_vm.crudModel.location),callback:function ($$v) {_vm.$set(_vm.crudModel, "location", $$v)},expression:"crudModel.location"}})],1),_c('template',{slot:"recommendImageForm"},[_c('UploadBtn',{attrs:{"limit":1,"tips":"上传数量需为双数, 最多100张"},model:{value:(_vm.crudModel.recommendImage),callback:function ($$v) {_vm.$set(_vm.crudModel, "recommendImage", $$v)},expression:"crudModel.recommendImage"}})],1),_c('template',{slot:"recommendedTombsForm"},[_c('div',{staticClass:"addBtns"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addClick}},[_vm._v("新增推荐墓型")])],1),(
          _vm.crudModel.recommendedTombs && _vm.crudModel.recommendedTombs.length > 0
        )?_c('div',_vm._l((_vm.crudModel.recommendedTombs),function(item,index){return _c('div',{key:index,staticClass:"cemeteryForm"},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_vm._v("第"+_vm._s(index + 1)+"项墓型")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.delItem(index)}}},[_vm._v("删除")])],1),_c('el-form-item',{attrs:{"label":"墓型图片","prop":'recommendedTombs.' + index + '.recommendImage',"rules":[
              {
                required: true,
                message: '请上传墓型图片',
                trigger: 'change',
              } ]}},[_c('UploadBtn',{attrs:{"limit":1,"tips":""},model:{value:(item.recommendImage),callback:function ($$v) {_vm.$set(item, "recommendImage", $$v)},expression:"item.recommendImage"}})],1),_c('el-form-item',{attrs:{"label":"墓型名称","prop":'recommendedTombs.' + index + '.tombName',"rules":[
              {
                required: true,
                message: '请输入墓型名称',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"请输入墓型名称"},model:{value:(item.tombName),callback:function ($$v) {_vm.$set(item, "tombName", $$v)},expression:"item.tombName"}})],1),_c('el-form-item',{attrs:{"label":"参考价","prop":'recommendedTombs.' + index + '.referencePrice',"rules":[
              { required: true, message: '请输入参考价', trigger: 'blur' } ]}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"min":1,"controls":false,"placeholder":"请输入参考价"},model:{value:(item.referencePrice),callback:function ($$v) {_vm.$set(item, "referencePrice", $$v)},expression:"item.referencePrice"}})],1),_c('el-form-item',{attrs:{"label":"所属陵园","prop":'recommendedTombs.' + index + '.cemeteryId',"rules":[
              {
                required: true,
                message: '请选择所属陵园',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"placeholder":"请选择所属陵园"},model:{value:(item.cemeteryId),callback:function ($$v) {_vm.$set(item, "cemeteryId", $$v)},expression:"item.cemeteryId"}},_vm._l((_vm.cemeteryList),function(ly){return _c('el-option',{key:ly.id,attrs:{"label":ly.cemeteryName,"value":ly.id}})}),1)],1)],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"请先上传推荐墓型图片"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import request from '@/router/axios'

// 分页查询推荐列表
export function getRecommendApi(query) {
  return request({
    url: '/admin/recommendIndex/page',
    method: 'get',
    params: query
  })
}
// 添加-修改陵园
export function postEditApi(obj) {
  return request({
    url: '/admin/recommendIndex/addOrUpdate',
    method: 'post',
    data: obj
  })
}
// 根据ID删除
export function deleteApi(id) {
  return request({
    url: `/admin/recommendIndex/deleteById/${id}`,
    method: 'delete',
  })
}
export function getCemeteryListdApi(query) {
  return request({
    url: '/admin/cemetery/list',
    method: 'get',
    params: query
  })
}
// 修改排序
export function postUpdateRankApi(obj) {
  return request({
    url: `/admin/recommendIndex/updateRank/${obj.id}/${obj.operateType}`,
    method: 'post'
  })
}